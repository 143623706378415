export default
`#### FCR (kg / kg of product)

The feed conversion ratio FCR indicates the ability of an animal to use
its diet to increase its body weight in the breeding conditions in which
it is found.

It is also defined as the quantity of feed, expressed in kg,
necessary to deposit one kg of live weight.

$$
FCR = \\frac F W
$$

- $F$ is **food administered** in grams
- $W$ is **weight increase** in grams
`;